
import Dialog from 'primevue/dialog'
import Card from 'primevue/card'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import ModalDefault from '@/views/components/modal/ModalDefault.vue'
import { DatePicker } from 'v-calendar'
import moment from 'moment'

import {
  defineComponent,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted
} from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const module = 'roster'

export default defineComponent({
  components: {
    // Card,
    InputText,
    // Dialog,
    Button,
    // SelectFetch,
    ModalDefault,
    DatePicker
  },
  setup() {
    const x = ref()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const showModal = computed({
      get: () => store.state.headerButton.isShowDialogForm,
      set: (val) => {
        store.dispatch('setHeaderButtonActionShowDialogForm', val)
      }
    })
    const headerLabel = ref('Pilih Tanggal')
    const headerDescription = ref('Pilih tanggal pariwisata. Anda dapat memilih rentang tanggal untuk pariwisata')
    const selectedKondektur = ref(null as any)
    const showErrorMessage = ref(false)
    const dateRange = ref({
      start: null,
      end: null
    })

    const closeDialog = () => {
      showModal.value = false
      console.log('closeDialog')
    }

    const setDaterange = () => {
      store.dispatch(`${module}/setRosterDaterange`, dateRange.value)
      // console.log('setPlottingKondektur', selectedKondektur.value)
      console.log('dateRange', store.state)
      closeDialog()
      router.push({
        path: 'tourist-roster-form'
      })
    }

    const showError = () => {
      if (selectedKondektur.value) {
        showErrorMessage.value = false
      } else {
        showErrorMessage.value = true
      }
    }

    onMounted(() => {
      if (route.path.includes('form')) {
        headerLabel.value = 'Ganti Tanggal'
      }
    })

    return {
      showModal,
      closeDialog,
      headerLabel,
      headerDescription,
      selectedKondektur,
      setDaterange,
      showErrorMessage,
      showError,
      dateRange,
      moment
    }
  }
})
